import * as Sentry from '@sentry/react';

import { getEnvironment } from 'mineralsoft/utils';

interface SentryConfig {
  accountName: string;
  id: string;
  organizationName: string;
  userEmail: string;
  userFullName: string;
  username: string;
}

const initializeSentry = (customData: SentryConfig) => {
  const environment = getEnvironment();

  const ignoreErrors = getErrorsToIgnore();

  Sentry.init({
    dsn: 'https://8d307f693f7c4be7859c3f7fc26de61a@sentry.io/1190009',
    enabled: !(environment === 'dev' || environment === 'localhost'),
    environment,
    ignoreErrors,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  configureCustomData(customData);
};

function configureCustomData({
  id,
  username,
  userEmail,
  organizationName,
  accountName,
  userFullName,
}: SentryConfig) {
  const scope = Sentry.getCurrentScope();
  scope.setUser({
    id,
    username,
    email: userEmail,
    'Full name': userFullName,
    'Account Name': accountName,
  });
  scope.setExtra('organizationName', organizationName);
}

function getErrorsToIgnore() {
  return [
    // Ignoring this error after plenty of research. Here's a handy link
    // to stack overflow https://stackoverflow.com/a/50387233
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    // When bundler fails to build the app. It happens because when non local env serves local JS
    'Error: Module build failed',
  ];
}

export default initializeSentry;
